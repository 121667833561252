import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const NewUserRegisterForFamilyTrust = () => {
  return (
    <Layout>
      <Seo
        title="How Can a New User Register for Family Trust?"
        description={`This guide will help new users register for a Family Trust using the Synkli mobile app.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`new-user-register-for-family-trust`} />
    </Layout>
  )
}

export default NewUserRegisterForFamilyTrust
